import { apiFetch } from "../wp"; 
// apiFetch not working at all don't know why, it is working if used like this wp.apiFetch

import { apiEndPoint } from "../constants";
import { apiTripEndPoint } from "../constants";

const $http = {
	get(){
		return wp.apiFetch({
			path: apiEndPoint,
			method: 'GET'
		});
	},
	add( data ){
		return wp.apiFetch({
			path: apiEndPoint,
			data,
			method: 'POST'
		});
	},
	update( data ){
		return wp.apiFetch({ 
			path  : `${ apiEndPoint }/${ data.ID }`, 
			data  : data,
			method: 'PUT' 
		});
	},
	delete( id ){
		return wp.apiFetch({
			path: `${ apiEndPoint }/${ id }/?force=true`,
			method: 'DELETE'
		});
	},

	get_trip( trip_id ){
		return wp.apiFetch({
			path: `${ apiTripEndPoint }/${ trip_id }`,
			method: 'GET'
		});
	}
};

export default $http;