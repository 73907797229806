import { render, domReady, __ } from "./../wp";
import GuideTabTemplate from "./tab-template";


{/* 
	Wrapper component - GuideTabTemplate
*/} 
const WPTravelTourGuidSingleTripGuideTab = () => {

	return 	<GuideTabTemplate />;

}


{/* 
	rendering template to target id
*/} 
domReady(() => {
	if (
		"undefined" !== typeof document.getElementById( "wp-travel-travel-guide-fornt-guide-tab-template" ) &&
		null !== document.getElementById( "wp-travel-travel-guide-fornt-guide-tab-template" )
	) {
		render( <WPTravelTourGuidSingleTripGuideTab />, document.getElementById( "wp-travel-travel-guide-fornt-guide-tab-template" ) );
	}
});
