import { storeName } from "./constants";

export const Fragment      = wp.element.Fragment;
export const render        = wp.element.render;
export const apiFetch      = wp.apiFetch;
export const withSelect    = wp.data.withSelect;
export const useSelect     = wp.data.useSelect;
export const domReady      = wp.domReady;

export const registerStore = args => wp.data.registerStore( storeName, args );
export const dispatch      = () => wp.data.dispatch( storeName );

export const Component     = wp.element.Component;
export const useState      = wp.element.useState;
export const useEffect      = wp.element.useEffect;

export const TextControl   = wp.components.TextControl;
export const SelectControl = wp.components.SelectControl;
export const CheckboxControl  = wp.components.CheckboxControl ;
export const TextareaControl = wp.components.TextareaControl;
export const __ = wp.i18n.__;

export const applyFilters = wp.hooks.applyFilters;
export const removeFilter = wp.hooks.removeFilter;
export const addFilter = wp.hooks.addFilter;
